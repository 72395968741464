function showAccordionTab() {
  // create and open Function

  const accordion = document.querySelectorAll('.ce_accordionStart');
  accordion.forEach((accordions) => {
    // Check if Formular inner with Error
    const errorCheck = accordions.querySelectorAll('.error');
    for (let i = 0; i < errorCheck.length; i++) {
      if (errorCheck !== null) {
        accordions.classList.add('-is-open');
        accordions.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      }
    }
    const accordionToggler = accordions.getElementsByClassName('toggler');
    accordionToggler[0].addEventListener('click', (event) => {
      event.preventDefault();
      console.log();
      if (accordions.classList.contains('-is-open')) {
        accordions.classList.remove('-is-open');
      } else {
        accordions.classList.add('-is-open');

        accordions.scrollIntoView({
          behavior: 'smooth',
        });
      }
    });
  });
}
window.addEventListener('load', showAccordionTab, false);
